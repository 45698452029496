
.label{
  
  
  
    //電腦版
    @media all and (min-width: 960px) {
      margin-bottom:140px ;
      margin-top: 100px;
    
      background-color: #211e55;
      background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);
      


}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  margin-top: 100px;
  margin-bottom:100px ;
  background-color: #211e55;
      background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  margin-top: 100px;
  margin-bottom:50px ;
  background-color: #211e55;
      background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);}
//手機
@media all and (max-width: 480px) {
  margin-top: 100px;
  margin-bottom:70px ;
  background-color: #211e55;
  background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);
}

}     
.label h1{
  color:#211E55 ;
  font-weight: bold;
}
.FullImage{
  width: 100%
}  
.MiddleImage{
  width: 80%
} 
