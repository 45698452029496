.fullScreen{
    height: 100vh;
}
// swipe
.SwipeTitleBg{
  //電腦版
  @media all and (min-width: 960px) {
    background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
height: 350px;
display: flex;
align-items: center
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
}
//手機
@media all and (max-width: 480px) {
  background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
  
}



}
.SwipeImage{
  width: 100%;
 height: auto
  
  }
.SwipeTitle{
text-align: initial;

}
.Label{
  //電腦版
  @media all and (min-width: 960px) {
      position: relative;
      bottom: 0px;
      left: -80px;   
      display: inline;
      background: linear-gradient(180deg, #45E7DD 0%, #4236D0 49.76%);
      background-clip: text;
      color: transparent;
      -webkit-text-stroke: 0.5px #FFFFFF;
      font-size: 64px;
      border-bottom: 1px solid #fff;
      text-shadow: 0px 0px 10px #ffffffc4;
      
     

  }
  //平板
  @media all and (min-width: 760px) and (max-width: 959px) {
    position: relative;
    bottom: 0px;
    display: inline;
    background: linear-gradient(180deg, #45E7DD 0%, #4236D0 49.76%);
      background-clip: text;
      color: transparent;
      -webkit-text-stroke: 0.5px #FFFFFF;
      font-size: 64px;
      border-bottom: 1px solid #fff;
      text-shadow: 0px 0px 10px #ffffffc4;
    
  }
  
  //小平板
  @media all and (min-width: 480px) and (max-width: 759px) {
    position: relative;
    bottom: 0px;
    display: inline;
      font-size: 60px;
      background: linear-gradient(180deg, #45E7DD 0%, #4236D0 49.76%);
      background-clip: text;
      color: transparent;
      -webkit-text-stroke: 0.5px #FFFFFF;
      // text-shadow: 0px 0px 10px #ffffffc4;
  }
  //手機
  @media all and (max-width: 480px) {
    position: relative;
    bottom: 0px;
    display: inline;
    font-size: 60px;
    background: linear-gradient(180deg, #45E7DD 0%, #4236D0 49.76%);
      background-clip: text;
      color: transparent;
      // -webkit-text-stroke: 0.5px #FFFFFF;
     
   
  }
  }
.BannerTitle{
  @media all and (min-width: 960px) {
    display: none;

  
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  display: none;

}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  display: none;
}
//手機
@media all and (max-width: 480px) {
 display: none;
}
 
}  

.backgroundImage{
  @media all and (min-width: 960px) {
    background-image: url("../../public/images/AR/groundImage.png");
    display: flex;
    align-items: center;
  
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  background-image: none;
  display: flex;
  align-items: center;

}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  background-image: none;
  display: flex;
  align-items: center;
}
//手機
@media all and (max-width: 480px) {
  background-image: none;
  display: flex;
  align-items: center;
}

}
// AR
.ARTitle{
    position:flex;
        justify-content: center;
  
}
.ARText{
    @media all and (min-width: 960px) {
        position:flex;
        justify-content: center;
        margin-left: 20%;
      
  }
  //平板
  @media all and (min-width: 760px) and (max-width: 959px) {
    position:flex;
    justify-content: start;
   
  }
  //小平板
  @media all and (min-width: 480px) and (max-width: 759px) {
    position:flex;
    justify-content: start;
   
  }
  //手機
  @media all and (max-width: 480px) {
    position:flex;
    justify-content: start;
    
  }
}

.ARModel{
    position:flex;
    justify-content: center;
}

.ARModelView{
  height: 100%;
  width: 100%;
}

.OuterBox{
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
}
.ARWebmBox h1{
  font-weight: bold;
  text-align: center;
}
.ARWebmBox:hover{
  @media all and (min-width: 960px) {
    color: #211E55;
    background-color: #90E0EF;
  
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  color: #ffffff;
  background-color: #211E55;

}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  color: #ffffff;
  background-color: #211E55;
}
//手機
@media all and (max-width: 480px) {
  color: #ffffff;
  background-color: #211E55;
}

}
// .ARWebmBox:active {
//   color: #ffffff;
//   background-color: #211E55;
// }
.ARWebm{
  position: fixed;
  top: 10%;
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index:100;
  pointer-events: none ,
}
.ARWebm2{
  position: fixed;
  top: 10%;
  right: 0%;
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index:100;
  pointer-events: none ,
}
.ARVideoRWD{
  @media all and (min-width: 960px) {
    width: 400px;
    display: none
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  width: 400px;
  display:  block
  
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  width: 400px;
  display: block
}
//手機
@media all and (max-width: 480px) {
  width: 400px;
  display: block
  
}
}
.ARVideoShowRWD{
  @media all and (min-width: 960px) {
    display: block
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  display: none
  
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  display: none;
 
}
//手機
@media all and (max-width: 480px) {
  display: none;
  
  
}
}
.ARAspanShowRWD{
  @media all and (min-width: 960px) {
    display: inline
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  display: inline
  
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  display: none;
 
}
//手機
@media all and (max-width: 480px) {
  display: none;
  
  
}
}
// VR

.VRVideoItem{
  height: 640px;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
}
.VRVideo{
  
  position: flex;
  min-height: 640px;
  filter: brightness(50%)blur(1px) opacity(80%)grayscale(10%)
}
.VRVideoTitle{
   //電腦版
   @media all and (min-width: 960px) {
    margin-bottom: 10%;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  margin-bottom: 15%;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  margin-bottom: 25%;
}
//手機
@media all and (max-width: 480px) {
  margin-bottom: 25%;
  
}

  
}
// MR
.MRVideoItem{
  position: flex;
  height:100vh;
}
.MRTitleLeft{
  //電腦版
  @media all and (min-width: 960px) {
    background:
    linear-gradient(90deg, #00B4D8 0%, rgba(0, 180, 216, 0) 100%);
height: 450px;
display: flex;
align-items: center;
justify-content: center;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
  padding: 5%;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
  padding: 5%;
}
//手機
@media all and (max-width: 480px) {
  background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
  padding: 5%;
  
}
}
.MRTitleRight{
  //電腦版
  @media all and (min-width: 960px) {
    background:
    linear-gradient(-90deg, #00B4D8 0%, rgba(0, 180, 216, 0) 100%);
height: 450px;
display: flex;
align-items: center;
justify-content: center;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  background:linear-gradient(-90deg, #00B4D8 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
  padding: 5%;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  background:linear-gradient(-90deg, #00B4D8 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
   padding: 5%;
}
//手機
@media all and (max-width: 480px) {
  background:linear-gradient(-90deg, #00B4D8 0%, rgba(0, 180, 216, 0) 100%);
  height: 100%;
  padding: 5%;
}
}
.MRTitle{
  text-align: center;
  }
.MRImgLeft{
  //電腦版
  @media all and (min-width: 960px) {
   
    width: 100%;
    object-fit: cover;
    box-shadow: -15px 15px rgba(0, 180, 216, 0.5),
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  width: 100%;
    object-fit: cover;
    box-shadow: -15px 15px rgba(0, 180, 216, 0.5),
}

//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  width: 100%;
    object-fit: cover;
}

//手機
@media all and (max-width: 480px) {
  width: 100%;
    object-fit: cover;
   
}
}
.MRImgRight{
  //電腦版
  @media all and (min-width: 960px) {
   
    width: 100%;
    object-fit: cover;
    box-shadow: 15px 15px rgba(0, 180, 216, 0.5),
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  width: 100%;
    object-fit: cover;
    box-shadow: 15px 15px rgba(0, 180, 216, 0.5);
   
}

//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  width: 100%;
    object-fit: cover;
}

//手機
@media all and (max-width: 480px) {
  width: 100%;
    object-fit: cover;
   
}
}
//CAVE
.CAVEImgRight{
  width: 100%;
  object-fit: cover;
}