// 變數覆蓋需要在導入Bootstrap的Sass檔案之前
// 參考Bootstrap的 _variables.scss
html {
  scroll-behavior: smooth;
}

// 主要背景色
$background-color: #211E55;

// 主要字體色
$font-color: #FFFFFF;

// 導入bootstrap所有的樣式與預設變數
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans TC', sans-serif;
  color: $font-color;
  background-color: $background-color;
  line-height: 1.5; 
}
h1,h2,h3,h4,h5,h6,p { 
  line-height: 1.5em;
}

// 彈跳視窗背景
.background-pop {
  background-color: rgba(225, 225, 225, 40%);
  filter: blur(10px);
}


// 陰影
.shadow {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 5%);
}

//icon大小
.icon-btn {
  border: none;
  font-size: 20px;
}


.icon-btn {
  border: none;
  font-size: 20px;
}

hr {
  margin: 0;
  border: 1px solid #c7c7c7;
  background: #c7c7c7;
}
a {
  text-decoration:none;
}
.navbar li>.active {
  font-weight: bold;
}
.unique{
  background-image: linear-gradient(90deg, #3a7cc7 0%, rgba(1, 93, 156, 0) 100%);
  mix-blend-mode: hard-light;
}
.unique h2{
  margin-left: 5%;
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 4px #838383;
  opacity: 1;
}
#fp-nav ul li a span, .fp-slidesNav ul li a span{
  opacity: 0.5;
  background: #fffefe;;
  box-shadow: 2px 2px 4px #838383;
}
.fp-responsive .fp-section,
.fp-responsive .fp-slide,
.fp-responsive .fp-tableCell{
    height: auto !important;
    margin-top: 35px;
    margin-bottom: 35px;
}
model-viewer#reveal {
  --poster-color: #211E55;
}
.RWDBanner{
     //電腦版
     @media all and (min-width: 960px) {
      margin-top: 0px;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  margin-top: 0px;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  margin-top: 70px;}
//手機
@media all and (max-width: 480px) {
  margin-top: 70px;
}

}
.RWDBanner{
     //電腦版
     @media all and (min-width: 960px) {
      margin-top: 0px;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  margin-top: 0px;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  margin-top: 70px;}
//手機
@media all and (max-width: 480px) {
  margin-top: 70px;
}

}
