.playerWrapper{
    //電腦版
    @media all and (min-width: 960px) {
        display: flex;
    // bottom:200px;
    position: relative;
    justify-content: center;
  }
  //平板
  @media all and (min-width: 760px) and (max-width: 959px) {
    display: flex;
    position: relative;
    justify-content: center;
  }
  //小平板
  @media all and (min-width: 480px) and (max-width: 759px) {
    display:none;}
  //手機
  @media all and (max-width: 480px) {
    display:none;
  }
   
}
.playerRWDWrapper{
  //電腦版
  @media all and (min-width: 960px) {
      display:none;

}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  display:none;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  margin-bottom:50px ;
  display: flex;
  position: relative;
  justify-content: center;}
//手機
@media all and (max-width: 480px) {
  display: flex;
  bottom:0px;
  position: relative;
  justify-content: center;
}
 
}