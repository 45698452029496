
.label{
  margin-top: 100px;
  background-color: #211e55;
  background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);
}

 
.label h1{
  color:#211E55 ;
  font-weight: bold;

}

.textColor{
 color:#211e55
}
.from{
  //電腦版
  @media all and (min-width: 960px) {
    border-radius: 10px;
    background-image:
        linear-gradient(90deg, #FFFFFF 16.67%, rgba(255, 255, 255, 0.69) 53.91%, rgba(255, 255, 255, 0) 89.66%)
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  border-radius: 10px;
  background-image:
      linear-gradient(90deg, #FFFFFF 16.67%, rgba(255, 255, 255, 0.69) 53.91%, rgba(255, 255, 255, 0) 89.66%)
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  border-radius: 10px;
  background-color:#FFF
     
}
//手機
@media all and (max-width: 480px) {
  border-radius: 10px;
  background-color:#FFF
}
   
}
.ContactMap{
  //電腦版
  @media all and (min-width: 960px) {
    width: 100%;
    height: 500px;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  width: 100%;
  height: 500px;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  width: 100%;
    height: 400px;
     
}
//手機
@media all and (max-width: 480px) {
  width: 100%;
    height: 400px;
}
   
}
.imagePosition{
  //電腦版
  @media all and (min-width: 960px) {
   margin-bottom: 3%;
   width: 50%;
   height: auto;
   
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
 margin-bottom: 3%;
 width: 50%;
 height: auto;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
 margin-bottom: 3%;
 width: 50%;
 height: auto;
}
//手機
@media all and (max-width: 480px) {
 margin-bottom: 3%;
 width: 30%;
 height: auto;
}


}