body{
    margin:0;
    color: #fff;
  }

.RWDDisplay{
  @media all and (min-width: 960px) {
    display: block;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  display: block;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
display: none;
}
//手機
@media all and (max-width: 480px) {
  display: none;
}
}  
  .borderLeft{
      //電腦版
      @media all and (min-width: 960px) {
        border-left: 1px solid #fff;
    padding-left: 20px;
    margin:0 auto;
    text-align: left;
  }
  //平板
  @media all and (min-width: 760px) and (max-width: 959px) {
    border-left: 1px solid #fff;
    padding-left: 20px;
    margin:0 auto;
    text-align: left; 
  }
  //小平板
  @media all and (min-width: 480px) and (max-width: 759px) {
    
    padding: 20px;
    margin:0 auto;
    text-align: left;
  }
  //手機
  @media all and (max-width: 480px) {
  
    padding: 20px;
    margin:0 auto;
    text-align: left;
  }
  
  
    }
.buttonImage{
     //電腦版
     @media all and (min-width: 960px) {
      width: 80%;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
width:80%;   
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  display: none;
}
//手機
@media all and (max-width: 480px) {
  display: none;
}

}  

.buttonRWDImage{
   //電腦版
   @media all and (min-width: 960px) {
    display: none;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  display: none;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
width:80%;
}
//手機
@media all and (max-width: 480px) {
width: 80%;
}
}
.buttonImageUp{
  //電腦版
  @media all and (min-width: 960px) {
   width: 30%;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
width:20%;   
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
display: none;
}
//手機
@media all and (max-width: 480px) {
display: none;
}

}  

.buttonRWDImageUp{
//電腦版
@media all and (min-width: 960px) {
 display: none;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
display: none;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
width:20%;
}
//手機
@media all and (max-width: 480px) {
width: 30%;
}
}
.GifImage{
  //電腦版
  @media all and (min-width: 960px) {
    width: 80%;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
width:80%;   
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
width:50%;
}
//手機
@media all and (max-width: 480px) {
width: 50%;
}
 
}  
.FullImage{
  width: 100%
}
.CompanyImage{
  width: 80%

} 
.Company{
 
  //小平板
  @media all and (min-width: 480px) and (max-width: 759px) {
  display: none;
  }
  //手機
  @media all and (max-width: 480px) {
    display: none;
  
  }
 
  
} 
.CompanyRWD{
 
  @media all and (min-width: 960px) {
    display: none;
  }
  //平板
  @media all and (min-width: 760px) and (max-width: 959px) {
    display: none;
  }
 
  
} 
.MatteIframe{
  @media all and (min-width: 960px) {
    width: 100%;
    height: 500px;
    padding: 0;
    margin: 0;
    border: none;
  }
  //平板
  @media all and (min-width: 760px) and (max-width: 959px) {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    border: none;
  }
  //小平板
  @media all and (min-width: 480px) and (max-width: 759px) {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }
  //手機
  @media all and (max-width: 480px) {
    width: 100%;
    height:100%;
    padding: 0;
    margin: 0;
    border: none;
  
  
  }
 
  
}    
.MatteIframe iframe{
 //電腦版
 @media all and (min-width: 960px) {
  width: 100%;
  height: 100%;
  border: none;
  position: relative;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  width: 100%;
  height: 100%;
  border: none;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  width: 100%;
  height: 100%;
  border: none;
}
//手機
@media all and (max-width: 480px) {
  width: 100%;
  height: 100%;
  border: none;


}
   
}    

//company
.Icon{

  //電腦版
  @media all and (min-width: 960px) {
    width: 40%;
    height: 40%;
    margin: 10%;
    
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  width: 30%;
  height: 30%;
  margin: 5%;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  width: 30%;
    height: 30%;
    margin: 10%;
  }
//手機
@media all and (max-width: 480px) {
  width: 30%;
    height: 30%;
    margin: 10%;
  
}

}   
.IconSmall{
   
  //電腦版
  @media all and (min-width: 960px) {
    width: 30%;
    height: 30%;
    margin: 10%;
    
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  width: 20%;
  height: 20%;
  margin: 5%;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  width: 20%;
    height: 20%;
    margin: 10%;
  }
//手機
@media all and (max-width: 480px) {
  width: 20%;
    height: 20%;
    margin: 10%;
  
}
}
