
.logoWejump {
  padding-left: 20px;
}

  
  .navTitle {
    color: #fff !important;
    font-size: 20px;
    margin: 10px 10px;
  }
  .contact{
    background: linear-gradient(180deg, #91A1FF 36.15%, rgba(171, 180, 230, 0) 100%);
    
    
  }

  .navBg{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.14) 100%);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    z-index: 2;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
   
  }
  .navTitle:hover {
    box-shadow: inset 0 -2px 0 rgb(255, 255, 255) !important;
  }
  .active {
    color: #fff;
    background: #7600dc;
  }
  .nav a.inactive {
    font-style: italic;
  }
  