.label{
//電腦版
    @media all and (min-width: 960px) {
      margin-top: 100px;
      margin-bottom:50px ;
      background-color: #211e55;
      background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  margin-top: 100px;
  margin-bottom:50px ;
  background-color: #211e55;
      background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
  margin-top: 100px;
  margin-bottom:50px ;
  background-color: #211e55;
      background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);}
//手機
@media all and (max-width: 480px) {
  margin-top: 100px;
  margin-bottom:50px ;
  background-color: #211e55;
  background-image: linear-gradient(90deg, #211e55 0%, #a5d8b9 27%, #9ff6ff 49%, #a5d8b9 75%, #211e55 100%);
}

}     
.label h1{
  color:#211E55 ;
  font-weight: bold;
}
.FullImage{
    width: 100%
  }  
  
.cardBody{
    background: linear-gradient(180deg, #D6FFFD 0%, rgba(37, 192, 217, 0.48) 55.21%, rgba(113, 187, 255, 0.79) 100%);
    height: auto;
    text-align: center;
    
   
}
.cardImgSize{
  object-fit: cover;
}
.cardTitle{
    color: #211e55;
   margin-top: 5%;
}
.cardTag{
  position: relative;
  margin-bottom: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background:  #D6FFFD;
  mix-blend-mode: multiply;
  text-align: right;

  
 
}
.cardTag span{
  color: #fff;
  font-size: 16px;
  display: inline-block;
  margin-bottom: 0px;
  background: linear-gradient(-90deg, #559CF0 53.77%, #5AE1FF 100%);
  border: 1px solid #1E1D55;
 border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding:2px 5px ;
 margin:5px 10px 5px 0px ;
  font-weight: bold;
  
}

.cardSize{
    //電腦版
        @media all and (min-width: 960px) {
        margin: 20px;
        width: 80%;
        height:500px
    }
    //平板
    @media all and (min-width: 760px) and (max-width: 959px) {
        margin: 10px;
        width: 100%;
        height:auto
    }
    //小平板
    @media all and (min-width: 480px) and (max-width: 759px) {
        margin: 10px;
        width: 100%;
        height:auto
    }
    //手機
    @media all and (max-width: 480px) {
        margin: 10px;
        width: 100%;
        height:auto
      
    }
    
    } 
    .productBox{
      display: flex;
      position: relative;
      margin: auto;
      height: 280px; 
    }
    .cardMask{
      position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 50, 50, 0.6);
    opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .productBox a:hover .cardMask {
      opacity: 1;           
    }
    .Card.Img a:hover .mask {
      opacity: 1;           
    }
    .PopUp{
       //電腦版
    @media all and (min-width: 960px) {
      position: fixed;
      left: 37%;
      top: 5%;
      width: 25%;
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      
     
}
//平板
    @media all and (min-width: 760px) and (max-width: 959px) {
      position: fixed;
      left: 37%;
      top: 5%;
      width: 25%;
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      
     
     ;
}
//小平板
    @media all and (min-width: 480px) and (max-width: 759px) {
      position: fixed;
      left: 37%;
      top: 5%;
      width: 25%;
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      
     
    }
//手機
    @media all and (max-width: 480px) {
      position: fixed;
      left: 37%;
      top: 5%;
      width: 25%;
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      
     
}   
   
    }
    .popupX{
       //電腦版
    @media all and (min-width: 960px) {
      position:relative;
      top: -20px;
      
}
//平板
    @media all and (min-width: 760px) and (max-width: 959px) {
      position:relative;
      top: -20px;
     ;
}
//小平板
    @media all and (min-width: 480px) and (max-width: 759px) {
      position:relative;
      top: -20px;
      
    }
//手機
    @media all and (max-width: 480px) {
      position:relative;
      top: -20px;
      // left:160%;
}   
    
      
  }
    .PopBox{
      position:absolute;
      background-color: #211E55
    }
    .PopBox h5{
      color: white
    }
    .PopIframe{
      //電腦版
    @media all and (min-width: 960px) {
      height:720px;
      width:1280px
}
//平板
    @media all and (min-width: 760px) and (max-width: 959px) {
      height:600px;
      width:600px
}
//小平板
    @media all and (min-width: 480px) and (max-width: 759px) {
      height:400px;
      width:400px
    }
//手機
    @media all and (max-width: 480px) {
      height:400px;
      width:300px
}   
    }
    .formSelect option {
      background: #ffffff;
      color: black;    
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
     

      
  }
 
  .formSelect {
    color: #fff;
    background:linear-gradient(180deg, rgba(97, 255, 255, 0) 0%, #2379D0 100%);
  }

 