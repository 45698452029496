.Image{
    width: 100%; height: auto; object-fit: cover;
    }     
.Label{
//電腦版
@media all and (min-width: 960px) {
    position: relative;
     bottom: 100px;
   
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
    position: relative;
    bottom: 100px;
   
}

//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
    position: relative;
    bottom: 50px;
}
//手機
@media all and (max-width: 480px) {
    position: relative;
    bottom: 50px;
 
}
}
.TitleLeft{
    //電腦版
    @media all and (min-width: 960px) {
      background:
      linear-gradient(90deg, #00B4D8 0%, rgba(0, 180, 216, 0) 100%);
  height: 450px;
  display: flex;
  align-items: center;
  }
  //平板
  @media all and (min-width: 760px) and (max-width: 959px) {
    background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
    height: 450px;
    display: flex;
    align-items: center;
  }
  //小平板
  @media all and (min-width: 480px) and (max-width: 759px) {
    background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
    height: 100%;
    padding: 5%;
  }
  //手機
  @media all and (max-width: 480px) {
    background:linear-gradient(90deg, rgba(0, 180, 216, 0.75) 0%, rgba(0, 180, 216, 0) 100%);
    height: 100%;
    padding: 5%;
    
  }
  }
  .Title{
    @media all and (min-width: 960px) {
      margin-left: 10%;
    text-align:left; 
  }
  //平板
  @media all and (min-width: 760px) and (max-width: 959px) {
   
    text-align:left; 
  }
  //小平板
  @media all and (min-width: 480px) and (max-width: 759px) {
    
    text-align:left; 
  }
  //手機
  @media all and (max-width: 480px) {
    
    text-align:left; 
    
  }
   
    }

.ImgLeft{
  @media all and (min-width: 960px) {
  height: 450px;
  width: 100%;
  object-fit: cover;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
 height: 450px;
 width: 100%;
 object-fit: cover;
}
//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
height: auto;
width: 100%;
object-fit: cover;
}
//手機
@media all and (max-width: 480px) {
  height:auto;
  width: 100%;
  object-fit: cover;
  
}
  
      }