.particles{
  
      //電腦版
  @media all and (min-width: 960px) {
    position: fixed;
    width: 100%;
    z-index: -1;
}
//平板
@media all and (min-width: 760px) and (max-width: 959px) {
  position: fixed;
  width: 100%;
  z-index: -1;
}

//小平板
@media all and (min-width: 480px) and (max-width: 759px) {
 display: none;
}
//手機
@media all and (max-width: 480px) {
  display: none;
}
  }

